const baseUrl = process.env.REACT_APP_GOLEMIO_BASE_URL
const token = process.env.REACT_APP_GOLEMIO_TOKEN
const range = process.env.REACT_APP_GOLEMIO_RANGE

export async function fetchGolemio(params = '') {
    const rangeQuery = `range=${range}`
    const formattedParams =
        params !== '' ? `?${params}&${rangeQuery}` : `?${rangeQuery}`

    try {
        const response = await fetch(
            `${baseUrl}/sortedwastestations/${formattedParams}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': String(token),
                },
            }
        )

        return await response.json()
    } catch (e) {
        console.log('Error: ', e)
    }
}
