import React, { useEffect, useState } from 'react'
import { Wrapper } from '@googlemaps/react-wrapper'
import clsx from 'clsx'
import { fetchGolemio } from '../../../utils/fetchGolemio'
import { StationProps } from '../../../type/StationProps'
import { Map } from '../../organism/Map/Map'
import { Marker } from '../../primitive/Marker/Marker'
import styles from './MapWrapper.module.scss'

const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''

export const MapWrapper = () => {
    // geolocation contains current user position if it is allowed
    const [geolocation, setGeolocation] = useState<
        undefined | google.maps.LatLngLiteral
    >(undefined)

    // position is a current position which is used for searching
    // if geolocation is allowed and defined then position = geolocation, BUT only on init (position can change but geolocation stays)
    const [position, setPosition] = useState<
        undefined | google.maps.LatLngLiteral
    >(undefined)

    // position = currentFilteredPosition -> helper state to avoid triggering requests multiple times for same value
    // fetch runs only if currentFilteredPosition !== position
    const [currentFilteredPosition, setCurrentFilteredPosition] = useState<
        undefined | google.maps.LatLngLiteral
    >(undefined)

    // current stations results
    const [stations, setStations] = useState<StationProps[]>([])

    const zoom = 15

    useEffect(() => {
        if (currentFilteredPosition !== position) {
            // update currentFilteredPosition
            setCurrentFilteredPosition(position)

            // make fetch only when position is not undefined
            if (position !== undefined) {
                fetchGolemio(`latlng=${position.lat}%2C${position.lng}`).then(
                    (r) => {
                        console.log('returned response: ', r)
                        // check if a returned object has a features property and base on that set the yards state
                        if (
                            Object.prototype.hasOwnProperty.call(r, 'features')
                        ) {
                            setStations(r.features)
                        } else {
                            setStations([])
                        }
                    }
                )
            }
        }
    }, [position])

    return (
        <div className={clsx(styles.wrapper, !position && styles.invisible)}>
            <Wrapper apiKey={key}>
                <Map
                    center={position}
                    zoom={zoom}
                    position={position}
                    stations={stations}
                    setGeolocation={setGeolocation}
                    setPosition={setPosition}
                >
                    {geolocation && <Marker position={geolocation} />}
                    {position && <Marker position={position} />}
                </Map>
            </Wrapper>
        </div>
    )
}
