interface TrackLocationProps {
    onSuccess: PositionCallback
    onError: PositionErrorCallback
}

// use navigator.geolocation.watchPosition to watch user's position
export const watchPosition = ({ onSuccess, onError }: TrackLocationProps) => {
    console.log('track location')

    if ('geolocation' in navigator === false) {
        return new Error('Geolocation is not supported by your browser.')
    }

    return navigator.geolocation.watchPosition(onSuccess, onError, {
        enableHighAccuracy: true, // A boolean value that indicates the application would like to receive the best possible results. If true and if the device is able to provide a more accurate position, it will do so.
        timeout: 5000, // the maximum length of time (in milliseconds) the device is allowed to take in order to return a position.
        maximumAge: 0, // If set to 0, it means that the device cannot use a cached position and must attempt to retrieve the real current position.
    })
}

// use navigator.geolocation.getCurrentPosition to get user's current position
export const getCurrentPosition = ({
    onSuccess,
    onError,
}: TrackLocationProps) => {
    console.log('get current position')

    if ('geolocation' in navigator === false) {
        return new Error('Geolocation is not supported by your browser.')
    }

    return navigator.geolocation.getCurrentPosition(onSuccess, onError)
}
