import { StationProps } from '../type/StationProps'

// helper function to create a marker
export const createMarker = (markerObj: StationProps, map: google.maps.Map) => {
    const coordinates = markerObj.geometry.coordinates

    return new window.google.maps.Marker({
        position: {
            lat: coordinates[1],
            lng: coordinates[0],
        },
        map: map,
        icon: '/img/icon/trash.svg',
    })
}
