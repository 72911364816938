import React, { useEffect, useRef, useState } from 'react'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
import { useDeepCompareEffectForMaps } from '../../../utils/useDeepCompareEffectForMaps'
import { getCurrentPosition } from '../../../utils/geolocation'
import styles from './Map.module.scss'
import { handleGeocoder } from '../../../utils/handleGeocoder'
import { StationProps } from '../../../type/StationProps'
import { createMarker } from '../../../utils/createMarker'

// lat and lng for default position
const defaultLat = Number(process.env.REACT_APP_DEFAULT_CENTER_LAT || 0)
const defaultLng = Number(process.env.REACT_APP_DEFAULT_CENTER_LNG || 0)
const defaultRadius = Number(process.env.REACT_APP_GOLEMIO_RANGE || 1000)

interface MapProps extends google.maps.MapOptions {
    children?: React.ReactNode
    position: undefined | google.maps.LatLngLiteral
    stations: StationProps[]
    setGeolocation: React.Dispatch<
        React.SetStateAction<undefined | google.maps.LatLngLiteral>
    >
    setPosition: React.Dispatch<
        React.SetStateAction<undefined | google.maps.LatLngLiteral>
    >
}

export const Map = ({
    setGeolocation,
    setPosition,
    children,
    position,
    stations,
    ...options
}: MapProps) => {
    const ref = useRef<HTMLDivElement>(null)
    const [map, setMap] = useState<google.maps.Map>()
    const [circle, setCircle] = useState<google.maps.Circle>()

    // init map
    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {}))
        }
    }, [ref, map])

    // set options
    useDeepCompareEffectForMaps(() => {
        if (map) {
            map.setOptions(options)
            console.log('map hook')
        }
    }, [map, options])

    // hook on init
    useEffect(() => {
        // set the position state (fill it by geolocation's coordinates or by default values from .env)
        getCurrentPosition({
            onSuccess: ({ coords: { latitude: lat, longitude: lng } }) => {
                // check with geocoder that user is in Praha (geolocation is enabled only in Praha)
                handleGeocoder(
                    lat,
                    lng,
                    defaultLat,
                    defaultLng,
                    setPosition,
                    setGeolocation
                )
            },
            onError: (e) => {
                console.log(`Error ${e.code}: ${e.message}`)
                setPosition({ lat: defaultLat, lng: defaultLng })
            },
        })
    }, [])

    // hook for updating displayed stations
    useEffect(() => {
        if (map) {
            console.log('update clusters')

            const markers = stations.map((station) => {
                return createMarker(station, map)
            })

            new MarkerClusterer({ markers, map })
        }
    }, [stations, map])

    // hook for setting a circle representing a searched area
    useEffect(() => {
        if (position && map) {
            console.log('update circle')
            circle?.setMap(null)

            const localCircle = new google.maps.Circle({
                strokeColor: '#528FDF',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#528FDF',
                fillOpacity: 0.35,
                map,
                center: position,
                radius: defaultRadius,
            })

            localCircle.setMap(map)
            setCircle(localCircle)
        }
    }, [map, position])

    return (
        <>
            <div className={styles.wrapper} ref={ref} />
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    // set the map prop on the child component
                    // @ts-ignore
                    return React.cloneElement(child, { map })
                }
            })}
        </>
    )
}
