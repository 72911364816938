import React from 'react'
import { MapWrapper } from './components/block/MapWrapper/MapWrapper'

function App() {
    return (
        <>
            <MapWrapper />
        </>
    )
}

export default App
